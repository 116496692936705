import {
  BaseStepIdEnum,
  FieldEntityTypeEnum,
  FieldPropertyTypeEnum,
  FieldTypeEnum,
  StepConfig,
  StepTypeEnum,
} from 'shared-domain';

export const stepsConfig: StepConfig[] = [
  {
    id: BaseStepIdEnum.individual_edit,
  },
  {
    id: 'personal_address',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'street_address',
          nested: 'address',
          isRequired: true,
          isEnabled: true,
          type: FieldTypeEnum.text,
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'street_address_2',
          nested: 'address',
          isRequired: false,
          isEnabled: true,
          type: FieldTypeEnum.text,
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'postal_code',
          nested: 'address',
          isRequired: true,
          isEnabled: true,
          type: FieldTypeEnum.text,
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'city',
          nested: 'address',
          isRequired: true,
          isEnabled: true,
          type: FieldTypeEnum.text,
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'country',
          nested: 'address',
          isRequired: true,
          isEnabled: true,
          type: FieldTypeEnum.country,
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.default,
        },
      ],
    },
  },
  {
    id: 'fiscal_address',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'primary_fiscal_residence',
          isRequired: true,
          isEnabled: true,
          type: FieldTypeEnum.country,
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'us_person',
          isRequired: true,
          isEnabled: true,
          type: FieldTypeEnum.radio,
          options: ['true', 'false'],
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'has_secondary_fiscal_residence',
          isRequired: true,
          isEnabled: true,
          hasHelper: true,
          type: FieldTypeEnum.radio,
          options: ['true', 'false'],
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'secondary_fiscal_residence',
          isRequired: false,
          isEnabled: true,
          hasHelper: true,
          type: FieldTypeEnum.country,
          isMulti: true,
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.custom,
        },
      ],
    },
  },
  {
    id: 'employment_details',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'profession',
          isRequired: true,
          isEnabled: true,
          type: FieldTypeEnum.select,
          options: [
            'legal_representative_director',
            'cfo_finance_controller_accountant_treasurer',
            'other_executive_or_senior_management_position',
            'regulated_independent',
            'government_or_public_institution_employee',
            'position_in_a_nonprofit_or_religious_organization',
            'business_owner_entrepreneur_or_other_selfemployed_position',
            'others',
          ],
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'other_profession',
          isRequired: false,
          isEnabled: true,
          type: FieldTypeEnum.text,
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'has_risky_activity_sector',
          isRequired: true,
          isEnabled: true,
          hasHelper: true,
          type: FieldTypeEnum.select,
          options: [
            'no',
            'gambling_including_online_gambling',
            'manufacture_of_arms_and_ammunition',
            'b2b_trade_in_transport_and_war_material',
            'mining_of_other_nonferrous_metal_ores',
            'b2b_trade_in_ores_and_metals',
            'raw_tobacco',
            'cultivation_of_fiber_crops',
            'manufacture_of_tobacco_products',
            'retail_sale_of_tobacco_products_in_specialized_stores',
            'other_personal_services',
          ],
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'source_of_funds',
          isRequired: true,
          isEnabled: true,
          type: FieldTypeEnum.select,
          options: [
            'salary',
            'social_benefits_pension_or_others',
            'rental_income',
            'income_from_the_sale_of_real_estate',
            'interests_and_dividends',
            'income_from_inheritance',
            'others',
          ],
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.custom,
        },
      ],
    },
  },
  {
    id: 'business_relationship',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'iban',
          nested: 'banking_information',
          isRequired: true,
          isEnabled: true,
          type: FieldTypeEnum.iban,
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'bic',
          nested: 'banking_information',
          isRequired: false,
          isEnabled: true,
          type: FieldTypeEnum.bic,
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.default,
        },
      ],
    },
  },
];
